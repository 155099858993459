import styles from "../styles/components/Quote.module.css";
import React from "react";
import { Element } from 'react-scroll';

export default function Quote(props) {
  return (
    <Element id="Quote">
      <section className={`${styles.bgImg}`}>
        <div className={`container mt-3 p-3`}>
          <div className={`row justify-content-center pt-2 pb-2`}>
            <div className={`col-md-3 d-flex m-auto ${styles.mobileOrder1}`}>
              <img src="assets/img/generell/4.png" alt=""></img>
            </div>
            <div
              className={`col ${styles.mobileFlexBase1} d-flex justify-content-center m-auto`}
            >
              <div
                className={`caption center ${styles.quote} wow fadeInUp`}
                data-wow-delay=".3s"
              >
                <blockquote
                  className={`${styles.blockquote} justify-content-center m-1`}
                >
                  <q>{props.text}</q>
                  <cite>{props.author}</cite>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
}
