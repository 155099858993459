import "./App.css"
import { useEffect,  useRef } from "react"

import Banner from "./components/Banner"
import CallToAction from "./components/CallToAction"

import Navbar from "./components/Navbar"
import Offer from "./components/Offer"
import Picture from "./components/Picture"
import Change from "./components/Change"
import Qualification from "./components/Qualification"
import Quote from "./components/Quote"
import Contact from "./components/Contact"
import Footer from "./components/Footer"
import GoogleReviews from "./components/GoogleReviews"
import { useLoaderData, useLocation } from "react-router-dom"


function App(){

  const content = useLoaderData()[0]
  const navbarRef = useRef(null)
  const loaction = useLocation()

  function scroll(el){
    console.log(el)
    if(el){
      const element = document.getElementById(el.split('#').pop())
      if(element){
        setTimeout(() => {
          element.scrollIntoView(true, { behavior: "smooth", block: "end", inline: "center" })
        }, 200)
      }
    }
  }
  useEffect(() => {
    scroll(loaction.hash)
    var navbar = navbarRef.current;
    if (window.scrollY > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);


  return(
    <>
    <Navbar 
      id={navbarRef} 
      assets="./assets">
    </Navbar>
    
    <Banner 
      name = {content.banner_name}
      title = {content.banner_title}
      subtitle = {content.banner_untertitle}
      hastag = {content.banner_hashtag}
      text = {content.banner_text}
    ></Banner>

    <Offer
      title = {content.angebot_title}
      untertitle = {content.angebot_untertitle}
      text = {content.angebot_text}
      text_2 = {content.angebot_text_2}
    ></Offer>

    <CallToAction
      text = {content.cto_text}
      text2 = {content.cto_btn_text_1}
      text3 = {content.cto_btn_text_2}
      bulletpoints = {content.cto_bulletpoints}
    ></CallToAction>

    {/* <Leistungen
      text_2 = {content.cto_text_2}
    ></Leistungen> */}
    
    <Picture></Picture>
    
    <GoogleReviews></GoogleReviews>

    <Change
      graphics = {content.change_images}
      images = {content.images}
    ></Change>
    
    <Qualification
      siegel = {content.quali_siegel}
      images = {content.quali_siegel_images}
      bulletpoints = {content.quali_bulletpoints}
      showOnMobile = {content.showOnMobile}
    ></Qualification>
    
    <Quote
      text = {content.quote_text}
      author = {content.quote_author}
      image = {content.quote_image}
    ></Quote>
    
    <Contact></Contact>
    <Footer showButton={true}></Footer>
    </>
  )
}

export default App;