import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "../parts/Input";
import { useEffect, useState } from "react";
import { Checkbox } from "../parts/Checked";
import TextArea from "../parts/TextArea";
import { handleFileUpload, handleSubmit } from "../../utils/handlers";
import Swal from "sweetalert2";
import styles from "../../styles/components/BeitragSummary.module.css";

export default function BlogEdit() {
  const { id } = useParams();
  const [categories, setCategories] = useState();
  const [image, setImage] = useState();
  const { jwtToken } = useOutletContext("");
  const [post, setPost] = useState({
    author: "Anna von Canstein",
    icon: "/assets/img/generell/Homepage Fotos (1772 x 1185) (2).png",
    creation: getToday(),
    about: "Hypnose & Bewusstseinscoach",
    instagram: "https://www.instagram.com/anna_von_canstein/",
    youtube: "https://www.youtube.com/@AnnavonCanstein",
    mail: "https://g.page/r/Cf0dcuIuBb7sEAE/",
    linkedin: "https://www.linkedin.com/in/anna-von-canstein-842365225/",
  });
  const submitMethod = id === "0" ? "POST" : "PATCH";

  const navigate = useNavigate();
  const moveBack = () => {
    navigate("/admin/blog");
  };

  
  const isChecked = (id) => {
    if (!post.topics) return false;
    for (let i = 0; i < post.topics.length; i++) {
      if (post.topics[i].id === id) {
        return true;
      }
    }
    return false;
  };

  const toggleVisible = (event) => {
    setPost({
      ...post,
      ["visible"]: !post["visible"],
    });
  };

  const toggleShowPic = (event) => {
    setPost({
      ...post,
      ["show_pic"]: !post["show_pic"],
    });
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setPost({
      ...post,
      [name]: value,
    });
  };

  const handleCategorieChange = (topic) => () => {
    let match = false;
    if (!post.topics) {
      post.topics = new Array();
    }
    for (let i = 0; i < post.topics.length; i++) {
      if (post.topics[i].id === topic.id) {
        post.topics.splice(i, 1);
        match = true;
      }
    }
    if (!match) {
      post.topics.push(topic);
    }
    setPost({ ...post });
  };

  const changeImageFile = (event) => {
    // the FileAPI return a Filelist form the input element
    const fileInput = document.getElementById("image_file");
    const selectedImage = fileInput.files[0];
    setImage(selectedImage);
    let name = event.target.value.split("\\").pop();
    setPost({
      ...post,
      ["image"]: `/assets/posts/${name}`
    });
  };

  const removeImageFromPost = (id) => () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);
   
    let requestOptions = {
      method: "DELETE",
      headers: headers,
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/post_image?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          Swal.fire({
            title: "Fehler!",
            text: data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            position: "bottom-end",
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            timer: 2000,
            willClose: () =>{window.location.reload()}
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });

  }

  const uploadPost = () => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);
    let requestBody = [];
    requestBody.push(post);

    let requestOptions = {
      body: JSON.stringify(requestBody),
      method: submitMethod,
      headers: headers,
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/post`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          Swal.fire({
            title: "Fehler!",
            text: data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            position: "bottom-end",
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            timer: 2000
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });

    if (image) {
      const url = `${process.env.REACT_APP_BACKEND}/admin/post_image`;
      const formData = new FormData();
      formData.append("file", image);
      const headers = new Headers();
      headers.append("Authorization", "Bearer " + jwtToken);

      requestOptions = {
        method: "post",
        body: formData,
        headers: headers,
        credentials: "include",
      };
      handleFileUpload(url, requestOptions);
    }
    navigate("/admin/blog")
  };

  function getToday() {
    let d = new Date();
    let td = d.toISOString().split("T")[0];
    return td;
  }
  useEffect(() => {

    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }
    
    setPost({
      ...post,
      ["updated"]: getToday(),
    });
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    if (id !== "0") {
      // only fetch posting content if valid id
      fetch(`${process.env.REACT_APP_BACKEND}/postings/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setPost(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="container">
      <div className="row d-flex justify-content-start">
        <div className="col">
          <div>
            <button className="mb-10 p-2 btn btn-primary" onClick={moveBack}>
              Zurück
            </button>
          </div>
          <Input
            title={"Title"}
            className={"form-control"}
            type={"text"}
            name={"title"}
            value={post.title}
            onChange={handleChange("title")}
          ></Input>
          <Input
            title={"Kurze Zusammenfassung für die Übersicht"}
            className={"form-control"}
            type={"text"}
            name={"summary"}
            value={post.summary}
            onChange={handleChange("summary")}
          ></Input>
          <Input
            title={"Autor"}
            className={"form-control"}
            type={"text"}
            name={"author"}
            value={post.author}
            onChange={handleChange("author")}
          ></Input>
          <Input
            title={"Über den Autor"}
            className={"form-control"}
            type={"text"}
            name={"about"}
            value={post.about}
            onChange={handleChange("about")}
          ></Input>
          <Input
            title={"Icon"}
            className={"form-control"}
            type={"text"}
            name={"icon"}
            value={post.icon}
            onChange={handleChange("icon")}
          ></Input>
          <div className="d-flex">
            <Input
              title={"Erstellt am"}
              className={"w-50 form-control"}
              type={"date"}
              name={"creation"}
              value={post.creation}
              onChange={handleChange("creation")}
            ></Input>
            <Input
              title={"Aktualisiert am"}
              className={"w-50 form-control"}
              type={"date"}
              name={"updated"}
              value={getToday()}
              onChange={handleChange("updated")}
            ></Input>
          </div>
          <div className=" mt-20 d-flex text-center w-100 m-auto">
          <div>
          <Input
            title={"Bild auswählen"}
            className={"form-control"}
            type={"file"}
            name={"image_file"}
            onChange={changeImageFile}
          ></Input>
          {post.image != "" && 
          <>
          <button
            type="button"
            className="btn m-2 btn-danger"
            data-toggle="button"
            aria-pressed="false"
            onClick={removeImageFromPost(post.id)}
          >
            <span><i className="fa fa-trash"></i> Bild löschen</span>
          </button>
          </>}
          <h6 className="mt-2">Aktuelles Bild: {post.image !="" ? post.image : "keins"}</h6>
          </div>
          <div className={`${styles.postImg}`}>
            <img src={post.image}></img>
          </div>
          </div>
          <Input
            title={"Youtube link"}
            className={"form-control"}
            type={"text"}
            name={"video"}
            value={post.video}
            onChange={handleChange("video")}
          ></Input>
          <div className="mt-30 m-2">
            <h3>Weitere Einstellungen</h3>
            <h5>Anzeige</h5>
            <Checkbox
              label="Beitrag anzeigen"
              value={post.visible}
              onChange={toggleVisible}
            />
             <Checkbox
              label="Bild anzeigen"
              value={post.show_pic}
              onChange={toggleShowPic}
            />
          </div>
          <div className="mt-10">
            <h5>Kategorien</h5>
            {categories &&
              categories.map((categori) => (
                <Checkbox
                  label={categori.name}
                  value={isChecked(categori.id)}
                  onChange={handleCategorieChange(categori)}
                />
              ))}
              <button
                type="button"
                className="btn m-2 btn-primary"
                data-toggle="button"
                aria-pressed="false"
                onClick={()=>{navigate(`/admin/categories/`)}}
                >
                <span><i className="fa fa-pencil-alt"></i> Kategorien bearbeiten</span>
              </button>
          </div>
        
          <div className="mt-30">
            <h5>Blog Beitrag</h5>
            <TextArea
              title={"Aktueller Beitrag"}
              name={"content_text"}
              value={post.content_text}
              rows={"20"}
              onChange={handleChange("content_text")}
            />
          </div>

          <div>
            <button
              type="submit"
              className="btn btn-primary m-3"
              onClick={uploadPost}
            >
              Speichern
            </button>
            <a href="/admin/blog" className="btn btn-danger ms-2">
              Abbrechen
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
