import React from "react";
import styles from "../styles/components/Footer.module.css";

export default function Footer(props) {
  return (
    <footer className={` footer-half pb-0 ${styles.footer}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="cont">
              <div className="con-info">
                <ul className="mb-0">
                  <li className=" p-1 ls1">
                    <span>Email : </span> info@annavoncanstein.de
                  </li>
                </ul>
              </div>
              <div className="social-icon">
                <h6 className="custom-font stit mb-2 simple-btn">
                  Folge mir auf Social Media
                </h6>
                <div className="social">
                  <a
                    href="https://www.instagram.com/anna_von_canstein/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="icon"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/in/anna-von-canstein-842365225/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="icon"
                  >
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a
                    href="https://g.page/r/Cf0dcuIuBb7sEAE/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="icon"
                  >
                    <i className="fab fa fa-envelope"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/@AnnavonCanstein"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="icon"
                  >
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          {props.showButton && 
            <div
              className={`col-lg-5 offset-lg-2 d-flex align-items-center ${styles.mobileCenter}`}
            >
              <a
                href="https://calendly.com/annavoncanstein/ks"
                role="button"
                target="_blank"
                rel="noreferrer"
                className={`simple-btn cursor-pointer greenbtn mt-30`}
              >
                <span className={`${styles.smallOnMobile}`}>
                  Kostenloses Erstgespräch
                </span>
              </a>
            </div>
          }
        </div>
        <div className="copyrights text-center">
          <p>
            © 2022, Anna von Canstein. Rechtlicher Hinweis: Ich bin nicht
            therapeutisch tätig und es werden keine Heilversprechen gegeben.
            Create and Code by
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="http://www.creode.de"
            >
              &nbsp; Cre0de
            </a>
            .
          </p>
          <span>
            <p>
              <a href="/impressum">Impressum & Datenschutz</a>
            </p>
          </span>
        </div>
      </div>
    </footer>
  );
}
