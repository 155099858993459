import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Input from "../parts/Input";
import TextArea from "../parts/TextArea";
import Swal from "sweetalert2";
import Select from "../parts/Select";
import { handleFileUpload } from "../../utils/handlers";
import { handleSubmitEvent } from "../../utils/handlers";
import { Checkbox } from "../parts/Checked";

export default function LandingPageEdit() {
  const navigate = useNavigate();
  const [content, setContent] = useState({});
  const { jwtToken } = useOutletContext("");
  const [selectedImg, setSelectedImg] = useState(0);
  const [file, setFile] = useState();
  const [filename, setFileName] = useState();

  let errors = [];
  const hasError = (key) => {
    return errors.indexOf(key) !== -1;
  };

  const toggleVisible = (event) => {
    setContent({
      ...content,
      ["showOnMobile"]: !content["showOnMobile"],
    });
  };
  const uploadFile = () => {
    const url = `${process.env.REACT_APP_BACKEND}/admin/images`;

    const formData = new FormData();
    formData.append("file", file, content.images[selectedImg]);
    
    const headers = new Headers();
    headers.append("Authorization", "Bearer " + jwtToken);

    var requestOptions = {
      method: "post",
      body: formData,
      headers: headers,
      credentials: "include",
    }

    handleFileUpload(url, requestOptions, ()=>(navigate(0)))
  }
    
  const changeFile = (event) => {
    // the FileAPI return a Filelist form the input element
    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];
    setFile(selectedFile);
    setFileName(event.target.value);
  };

  const changeSelectedImg = (index) => () => {
    setSelectedImg(index);
  };
  const addItem = (arrayname, index) => () => {
    let tempArray = content[arrayname];
    tempArray[index].points.push("");
    setContent({ ...content, [arrayname]: tempArray });
  };

  const removeItem = (arrayname, index, subindex) => () => {
    let tempArray = content[arrayname];
    let subArray = tempArray[index].points;
    subArray.splice(subindex, 1);
    setContent({ ...content, [arrayname]: tempArray });
  };

  const handleSubArrayChange = (name, index, subindex) => (event) => {
    let tempArray = content[name];
    tempArray[index].points[subindex] = event.target.value;
    setContent({
      ...content,
      [name]: tempArray,
    });
  };

  const handleArrayChange = (name, index, field) => (event) => {
    let tempArray = content[name];

    if (field !== null) {
      tempArray[index] = {
        ...tempArray[index],
        [field]: event.target.value,
      };
    } else {
      tempArray[index] = event.target.value;
    }
    setContent({
      ...content,
      [name]: tempArray,
    });
  };

  const handleChange = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setContent({
      ...content,
      [name]: value,
    });
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/home`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setContent(data[0]);
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }, []);

  return (
    <>
      <h1>Anpassung Landingpage</h1>
      <hr />
      <form onSubmit={handleSubmitEvent(jwtToken, content, false, "/admin/home", "PATCH", ()=>{navigate(0)})}>
        <input type="hidden" name="id" value={content} id="id"></input>
        <div>
          <hr />
          <h2 className="mt-50">Anpassungen Banner</h2>
          <Input
            title={"Name"}
            className={"form-control"}
            type={"text"}
            name={"banner_name"}
            value={content.banner_name}
            onChange={handleChange("banner_name")}
            errorDiv={hasError("banner_name") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>

          <Input
            title={"Title"}
            className={"form-control"}
            type={"text"}
            name={"banner_title"}
            value={content.banner_title}
            onChange={handleChange("banner_title")}
            errorDiv={hasError("banner_title") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>

          <Input
            title={"Untertitle"}
            className={"form-control"}
            type={"text"}
            name={"banner_untertitle"}
            value={content.banner_untertitle}
            onChange={handleChange("banner_untertitle")}
            errorDiv={hasError("banner_untertitle") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>


          <Input
            title={"Hastag"}
            className={"form-control"}
            type={"text"}
            name={"banner_hashtag"}
            value={content.banner_hashtag}
            onChange={handleChange("banner_hastag")}
          ></Input>
          <TextArea
            title={"Text"}
            name={"banner_text"}
            value={content.banner_text}
            rows={"3"}
            onChange={handleChange("banner_text")}
            errorMsg={"Bitte einen Text eingeben"}
            errorDiv={hasError("banner_text") ? "text-danger" : "d-none"}
          />
        </div>
        <div>
          <hr />
          <h2 className="mt-50">Anpassungen Angebot</h2>
          <Input
            title={"Title"}
            className={"form-control"}
            type={"text"}
            name={"angebot_title"}
            value={content.angebot_title}
            onChange={handleChange("angebot_title")}
            errorDiv={hasError("angebot_title") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>

          <Input
            title={"Untertitle"}
            className={"form-control"}
            type={"text"}
            name={"angebot_untertitle"}
            value={content.angebot_untertitle}
            onChange={handleChange("angebot_untertitle")}
            errorDiv={hasError("angebot_untertitle") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>

          <TextArea
            title={"Text"}
            name={"angebot_text"}
            value={content.angebot_text}
            rows={"6"}
            onChange={handleChange("angebot_text")}
            errorMsg={"Bitte einen Text eingeben"}
            errorDiv={hasError("angebot_text") ? "text-danger" : "d-none"}
          />
          <TextArea
            title={"Text 2"}
            name={"angebot_text_2"}
            value={content.angebot_text_2}
            rows={"3"}
            onChange={handleChange("angebot_text_2")}
            errorMsg={"Bitte einen Text eingeben"}
            errorDiv={hasError("angebot_text") ? "text-danger" : "d-none"}
          />
        </div>
        <div>
          <hr />
          <h2 className="mt-50">
            Anpassungen <i>Call to Action</i>
          </h2>
          <Input
            title={"Text"}
            className={"form-control"}
            type={"text"}
            name={"cto_text"}
            value={content.cto_text}
            onChange={handleChange("cto_text")}
            errorDiv={hasError("cto_text") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>

          <h3 className="mt-10">Bulletpoints</h3>
          <div className="ms-10 mb-10">
            {content.cto_bulletpoints &&
              Array.from(content.cto_bulletpoints).map((b, i) => (
                <Input
                  key={`${i}`}
                  title={`Punkt ${i}`}
                  className={"form-control"}
                  type={"text"}
                  name={`cto_bulletpoint_${i}`}
                  value={content.cto_bulletpoints[i]}
                  onChange={handleArrayChange("cto_bulletpoints", i, null)}
                  errorDiv="d-none"
                  errorMsg={"Bitte einene Text eingeben"}
                ></Input>
              ))}
          </div>
          <Input
            title={"Text"}
            className={"form-control"}
            type={"text"}
            name={"cto_btn_text_1"}
            value={content.cto_btn_text_1}
            onChange={handleChange("cto_btn_text_1")}
            errorDiv={hasError("cto_text") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input> 
          <Input
            title={"Text"}
            className={"form-control"}
            type={"text"}
            name={"cto_btn_text_2"}
            value={content.cto_btn_text_2}
            onChange={handleChange("cto_btn_text_2")}
            errorDiv={hasError("cto_text") ? "text-danger" : "d-none"}
            errorMsg={"Bitte einene Text eingeben"}
          ></Input>
        </div>

        <div>
          <h2 className="mt-50">Anpassungen Qualifikation</h2>
          <Checkbox
              label="Siegel auf Mobile anzeigen?"
              value={content.showOnMobile}
              onChange={toggleVisible}
            />
          {content.quali_siegel &&
            content.quali_siegel.map((siegel, index) => (
              <div>
                <h5>{`Siegel ${index + 1}`}</h5>
                <div className="mt-2 ms-10 mb-30">
                  <Select
                    title={"Bild"}
                    values={content.quali_siegel_images}
                    name={`quali_siegel_${index}`}
                    current={siegel.image}
                    onChange={handleArrayChange("quali_siegel", index, "image")}
                  ></Select>
                  <Input
                    title={`href / url`}
                    className={"form-control"}
                    type={"text"}
                    name={`quali_siegel_href_${index}`}
                    value={content.quali_siegel[index].href}
                    onChange={handleArrayChange("quali_siegel", index, "href")}
                    errorDiv="d-none"
                    errorMsg={
                      "Bitte Url auf eine gültige Internetseite eingeben"
                    }
                  ></Input>
                  <Input
                    title={`url`}
                    className={"form-control"}
                    type={"text"}
                    name={`quali_siegel_url_${index}`}
                    value={content.quali_siegel[index].url}
                    onChange={handleArrayChange("quali_siegel", index, "url")}
                    errorDiv="d-none"
                    errorMsg={"Bitte einen Anzeigetext auswählen"}
                  ></Input>
                </div>
              </div>
            ))}
        </div>
        <div>
          <h2 className="mt-50">Qualifikationen</h2>
          <div>
            {content.quali_bulletpoints &&
              content.quali_bulletpoints.map((point, index) => {
                return (
                  <div className="mt-50 mb-20">
                    <div className="d-flex m-auto justify-content-start">
                      <Input
                        title={`Qualitfikation ${index + 1}`}
                        className={"form-control mb-10"}
                        type={"text"}
                        name={`qualifikation_${index}`}
                        value={point.head}
                        onChange={handleArrayChange(
                          "quali_bulletpoints",
                          index,
                          "head"
                        )}
                        errorDiv="d-none"
                        errorMsg={"Bitte einen Anzeigetext auswählen"}
                      ></Input>
                      <button
                        type="button"
                        onClick={addItem("quali_bulletpoints", index)}
                        className="btn ms-10 mt-20 mb-20 btn-primary"
                        data-toggle="button"
                        aria-pressed="false"
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    {point.points.map((p, subindex) => {
                      return (
                        <div className="d-flex m-auto justify-content-start">
                          <Input
                            title={`Punkt ${subindex + 1}`}
                            className={"form-control w-100 ms-20  "}
                            type={"text"}
                            name={`qualifikation_content_${index}_${subindex}`}
                            value={p}
                            onChange={handleSubArrayChange(
                              "quali_bulletpoints",
                              index,
                              subindex
                            )}
                            errorDiv="d-none"
                            errorMsg={"Bitte einen Anzeigetext auswählen"}
                          ></Input>
                          <button
                            type="button"
                            onClick={removeItem(
                              "quali_bulletpoints",
                              index,
                              subindex
                            )}
                            className="btn ms-30 mt-20 mb-20 btn-danger"
                            data-toggle="button"
                            aria-pressed="false"
                          >
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
          </div>
        </div>
        <div>
          <h2 className="mt-50">Zitat</h2>
          <Input
            title={`Zitat Bild (Link)`}
            className={"form-control"}
            type={"text"}
            name={`quote_image`}
            value={content.quote_image}
            onChange={handleChange("quote_image")}
            errorDiv="d-none"
            errorMsg={"Bitte Bild auswählen"}
          ></Input>
          <Input
            title={`Author`}
            className={"form-control"}
            type={"text"}
            name={`quote_author`}
            value={content.quote_author}
            onChange={handleChange("quote_author")}
            errorDiv="d-none"
            errorMsg={"Bitte Text eingeben"}
          ></Input>
          <TextArea
            title={"Zitat Text"}
            name={"quote_text"}
            value={content.quote_text}
            rows={"3"}
            onChange={handleChange("quote_text")}
            errorMsg={"Bitte einen gültigen Text eingeben"}
            errorDiv={hasError("cto_text_2") ? "text-danger" : "d-none"}
          />
        </div>
        <div>
          <button type="submit" className="btn btn-primary m-3">
            Speichern
          </button>
          <a href="/admin/landingpage" className="btn btn-danger ms-2">
            Abbrechen
          </a>
        </div>
      </form>
      <div className="mt-20 mb-20">
        <h2 className="mt-50 ">Bilder</h2>
        <div className="row text-center text-lg-start">
          {content.images &&
            content.images.map((image, index) => (
              <div className="col-lg-3 col-md-4 col-6 p-0">
                <button
                  type="button"
                  onClick={changeSelectedImg(index)}
                  className={
                    " h-100 " + (index === selectedImg ? "active" : "")
                  }
                >
                  <img className="img-fluid img-thumbnail" src={image} alt="" />
                </button>
              </div>
            ))}
          <p>Aktuelles Bild: {content.images && content.images[selectedImg]}</p>

          <div className="input-group mb-3">
            <input
              type="file"
              class="form-control"
              value={filename}
              onChange={changeFile}
              id="fileInput"
            />
          </div>
          <button
            type="button"
            onClick={uploadFile}
            class="btn btn-warning mt-2 fs-1btn-lg btn-block"
          >
            Neues Bild hochladen
          </button>
        </div>
      </div>
    </>
  );
}
