import styles from "../styles/components/Aktuelles.module.css"
import Newsletter from "../components/Newsletter"
import { useEffect, useState } from "react";
import Swal from 'sweetalert2'
import {Link} from 'react-router-dom'

export default function Aktuelles(){

  const [latest, setLatest] = useState()

  useEffect( () => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    fetch(`${process.env.REACT_APP_BACKEND}/latest`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
            setLatest(data)
        })
        .catch((error)=>{
          Swal.fire({
            title: "Fehler!",
            text: error,
            icon: "error",
            confirmButtonText: "OK",
          });
        })

  },[]);
  
  return(
    <>
    <section className="mt-50 mb-50">
    <div className="container">
    <div className="sec-head mb-0 custom-font text-center">
          <h6 className=" fadeIn">
            Neuigkeiten
          </h6>
            <h3 className=" words chars">
              Workshop
            </h3>
          <span className="tbg">Events</span>
        </div>
    	<div className="row valign justify-content-center">
      <Newsletter></Newsletter>
      <hr/>
      {latest && latest.map((item) =>{ return(
        item.show && (
        <div className="col-lg-6 justify-content-center d-flex mx-auto mb-50">
          <div className={`card ${styles.profile_card_2}`}>
            <div className={`${styles.card_img_block}`}>
                <img className="img-fluid w-80" src={item.link} alt=""/>
                <div className={`justify-content-center p-0 d-flex ${styles.apply}`}>
                <a href="/#kontakt" 
                className={`simple-btn cursor-pointer greenbtn mt-20`}
                ><span>jetzt anmelden</span></a>
            </div>
          </div>
          </div>
        </div>
        ))
      })}
    </div>
  </div>
</section>	
</>
)}