import Markdown from "react-markdown"

export default function Impressum(props){

  return(
   <div id="impressum" className="section-ptb container">
     <div className="row">
       <div className="col">
       <Markdown>{props.data.content}</Markdown>  
       </div>
     </div>
   </div>
  )
}