import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import styles from "../../styles/components/ReviewEdit.module.css";
import { useNavigate, useOutletContext} from "react-router-dom";

export default function BlogEdit() {
  const [postings, setPostings] = useState([]);
  const [selectedPost, setSelectedPost] = useState(0);
  const { jwtToken } = useOutletContext("");
  var navigate = useNavigate();

  const moveTo = (id) => () => {
    navigate(`/admin/blog/${id}`);
  };

  const removePost = (id) => () => {
    Swal.fire({
      title: "Blog Beitrag löschen?",
      text: "Möchtest du den Blog Beitrag wirklich löschen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja! Löschen.",
      cancelButtonText: "Nein",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const headers = new Headers();
          headers.append("Content-Type", "application/json");
          headers.append("Authorization", "Bearer " + jwtToken);
          let query = "?post_id=" + id;
          const requestOptions = {
            method: "DELETE",
            headers: headers,
            credentials: "include",
          };

          fetch(`${process.env.REACT_APP_BACKEND}/admin/post${query}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              Swal.fire({
                title: "Ok",
                text: data.message,
                icon: "success",
                confirmButtonText: "OK",
              });
            })
            .catch((error) => {
              Swal.fire({
                title: "Fehler!",
                text: error,
                icon: "error",
                confirmButtonText: "OK",
              });
            });
        } else {
          console.log("Löschen abgebrochen");
        }
      })
      .catch((error) => {
        console.log("error");
      });
  };

  useEffect(() => {

    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }
    
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/postings`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPostings(data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }, []);
  return (
    <>
      <div className="container">
        <div className="row mb-4">
          <div className="col">
            <h3>Deine aktuellen Blogs {postings.length}</h3>
            <div className={`list ${styles.limitList}`}>
              <ul className="list-group text-dark">
                {postings.map((post, index) => {
                  return (
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        key={`${index}`}
                        className={
                          "list-group-item w-100 d-flex justify-content-between "
                        }
                      >
                        {post.title}. Erstellt am {post.creation}. Autor
                        {post.author}.{" "}
                        <b>{post.visible ? "sichtbar" : "nicht sichtbar"}</b>
                      </button>
                      <button
                        type="button"
                        className="btn m-2 btn-danger"
                        data-toggle="button"
                        aria-pressed="false"
                        onClick={removePost(post.id)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                      <button
                        type="button"
                        className="btn m-2 btn-primary"
                        data-toggle="button"
                        aria-pressed="false"
                        onClick={moveTo(post.id)}
                      >
                        <i className="fa fa-pencil-alt"></i>
                      </button>
                    </div>
                  );
                })}
              </ul>
            </div>
            <button
              type="button"
              className="btn ms-10 mt-20 mb-20 btn-primary"
              data-toggle="button"
              aria-pressed="false"
              onClick={moveTo(0)}
            >
              <i className="fa fa-plus"></i>
            </button>
            <span className="ms-10">Neuen Blog Beitrag erstellen</span>
          </div>
        </div>
      </div>
    </>
  );
}
