import React from "react";
import Slider from "react-slick";
import removeOverlay from '../utils/helperFunctions';
import styles from '../styles/components/Testimonials.module.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Element } from 'react-scroll';
import GoogleReview from "./parts/GoogleReview";
import Swal from 'sweetalert2'
import { isOnMobile } from "../utils/helperFunctions";

function getSettings(){
  var settings
  if(isOnMobile()){
  settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 2500,
    autoplay: true,
    rows: 1,
    slidesToScroll: 1,
    slidesToShow: 1,
 
  }
} else {
  settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 2500,
    autoplay: true,
    rows: 1,
    slidesToScroll: 3,
    slidesToShow: 3,

  }
}
return settings
}

export default class GoogleReviews extends React.Component {
  constructor(){
    super()
    this.state = {
      reviews: []
    }
  }

  componentWillMount = () => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    fetch(`${process.env.REACT_APP_BACKEND}/google_reviews`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
          this.setState({ reviews: data})
        })
        .catch((error)=>{
          Swal.fire({
            title: "Fehler!",
            text: error,
            icon: "error",
            confirmButtonText: "OK",
          });
        })
  }
 
  renderArrows = () => {
    return (

        <div className="col-6 text-right">
            <a className={`${styles.button} btn mb-3 mr-1`} role="button" onClick={() => this.slider.slickNext()}>
                <i className="fa fa-arrow-left"></i>
            </a>
            <a className={`btn mb-3 ${styles.button}`} role="button"  onClick={() => this.slider.slickPrev()}>
                <i className="fa fa-arrow-right"></i>
            </a>
        </div>
     
    )
  }

  componentDidMount() {
    removeOverlay();
  }

  render() {
    return (
      <Element id="feedback">
        <section
          className={`mt-100 mb-100 pb-0 ${styles.bgimg} `}
          style={{ backgroundImage: `url(/assets/img/generell/pattern.png)`}}
        >
      <div className={`sec-head custom-font text-center `}>
      <h6 >
        Das sagen Kunden:innen
      </h6>
        <h3>
          Feedback
        </h3>
      <span className="tbg">Meinungen</span>
      </div>
        <div className="container position-re">
          <div className="row mobileTopMargin justify-content-center">
            <div className="col justify-content-start">
              <h2 className="mb-3">Google Reviews</h2>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Slider
                {...getSettings()}
              >
               {this.state.reviews.map(review => (
                <GoogleReview
                {... { 
                  author: review.author,
                  icon: review.icon,
                  certified: review.certified,
                  rating: review.rating,
                  review: review.review,
                  creationDate: review.creationDate
                }} key={`${review.id}`}></GoogleReview>))}
              </Slider>
        </div>
        </div>
        </div>
        </section>
      </Element>
    );
  }
}
