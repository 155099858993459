import { useEffect, useState } from "react";
import { Checkbox } from "../parts/Checked";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { handleSubmitEvent, handleFileUpload } from "../../utils/handlers";

export default function AktuellesEdit() {
  const [latest, setLatest] = useState([]);
  const [selectedImg, setSelectedImg] = useState(0);
  const [file, setFile] = useState();
  const [filename, setFileName] = useState();
  const { jwtToken } = useOutletContext("");
  const navigate = useNavigate()

  const changeSelectedImg = (index) => () => {
    setSelectedImg(index);
  };

  const handleSelectionChange = (index) =>()=>{
    var val = latest[index].show ? false : true
    latest[index].show = val
    setLatest([...latest])
  }

  const uploadFile = () => {
    const url = `${process.env.REACT_APP_BACKEND}/admin/latest`;
   
    const formData = new FormData();
    formData.append("file", file);
   
    const headers = new Headers();
   
    headers.append("Authorization", "Bearer " + jwtToken);
   
    var requestOptions = {
      method: "POST",
      body: formData,
      headers: headers,
      credentials: "include",
    }
    handleFileUpload(url, requestOptions, ()=>{window.location.reload()})
  }

 async function removeFile(){
    const url = `${process.env.REACT_APP_BACKEND}/admin/latest`

    const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwtToken);
  
  var requestOptions = {
    method: "DELETE",
    body: JSON.stringify(latest[selectedImg]),
    headers: headers,
  }
  
  handleFileUpload(url, requestOptions, () =>{window.location.reload()})
  }
  
  const changeFile = (event) => {
    // the FileAPI return a Filelist form the input element
    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];
    setFile(selectedFile);
    setFileName(event.target.value);
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/latest`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLatest(data);
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }, []);

  return (
    <>
      <div>Aktuelles Edit</div>
      <div className="mt-20 mb-20">
        <h2 className="mt-50 ">Bilder</h2>
        <div className="row text-center text-lg-start">
          {latest.length > 0 && latest.map((item, index) => {
            return(
              <div className="col-lg-3 col-md-4 col-6 p-0">
                <button
                  type="button"
                  onClick={changeSelectedImg(index)}
                  className={
                    " h-100 " + (index === selectedImg ? "active" : "")
                  }
                >
                  <img
                    className="img-fluid img-thumbnail"
                    src={item.link}
                    alt=""
                  />
                </button>
                <div className="form-check">
                  <Checkbox
                    label="Anzeigen"
                    value={item.show}
                    onChange={handleSelectionChange(index)}
                  />
                </div>
              </div>
          )}
            )}
        </div>
        <div className="row mt-50">
          <p>Aktuelles Bild: {latest.length > 0 && latest[selectedImg].link}</p>
        </div>

        <div className="input-group mt-3 mb-3">
          <input
            type="file"
            className="form-control"
            value={filename}
            onChange={changeFile}
            id="fileInput"
          />
        </div>
        <div className="d-flex">
          <button
            type="button"
            className="btn btn-success mt-2 fs-1btn-lg btn-block"
            onClick={handleSubmitEvent(jwtToken, latest, true, "/admin/latest", "PATCH", ()=>{window.location.reload()})}
          >
            Änderungen Speichern
          </button>
          <button
            type="button"
            onClick={uploadFile}
            className="btn btn-warning ms-10 mt-2 fs-1btn-lg btn-block"
          >
            hochladen
          </button>
          <button
            type="button"
            onClick={removeFile}
            className="btn btn-danger ms-10 mt-2 fs-1btn-lg btn-block"
          >
            entfernen
          </button>
        </div>
      </div>
    </>
  );
}
