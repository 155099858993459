import React, { useEffect } from "react";
import styles from '../styles/components/Banner.module.css';
import {Element} from "react-scroll"

export default function Banner(props){
  return (
    <Element id="home" className="mb-20">
    <header
      className={`${styles.banner} ${styles.mobileMargin} valign bg-img mb-100`}
      data-overlay-dark="2"
    >
      <div className={` container d-flex `}>
        <div className={`row w-100 d-flex justify-content-between ${styles.nowrap}`}>
          <div className={`col-8  d-flex justify-content-start ps-20  `}>
            <div className={`${styles.mobileNoMarginLeft} ${styles.mobileNoTopMargin}`}>
            <div className={`${styles.mobileSpaceBetween}`}>
              <h4 className="rose pb-10">{props.name}</h4>
              <h2 className="pb-20 gold">{props.title}</h2>
              <h5 className="">{props.subtitle}</h5>
              <h5 className={styles.mobileMargin}>{props.hastag}</h5>
            </div>
              <div className={`${styles.social_icon}`}>
                <a href="https://www.instagram.com/anna_von_canstein/" target="_blank" rel="noreferrer noopener" className={styles.icon}>
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/in/anna-von-canstein-842365225/" target="_blank" rel="noreferrer noopener" className={styles.icon}>
             
                  <i className="p-1 fab fa-linkedin-in"></i>
            
                </a>
                <a href="https://www.youtube.com/@AnnavonCanstein" target="_blank" rel="noreferrer noopener" className="icon">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="#contact">
                  <i className="fa fa-envelope"></i>
                </a>
              
                <p className={`fs-3 pt-4  ${styles.mobileNoPadding} ${styles.mobileFont}`} data-wow-delay="1.0s">
                {props.text}
                </p>
              </div>
            </div>
          </div>
          <div className= {`col d-flex ${styles.bannerImgWrapper}`} >
            <img className={`${styles.bannerImg}`} src="/assets/img/transparent_bg/Homepage Fotos (1772 x 1185) Transparenter Hintergrund (1).png"></img>
          </div>
         
        </div>
      </div>

    </header>

    </Element>

  );
};
