import React from "react";
import { Formik, Form, Field } from "formik";
import { Element } from "react-scroll";
import styles from "../styles/components/Contact.module.css";
import { validateEmail, sendMessage } from "../utils/helperFunctions";
import Swal from 'sweetalert2'

export default function Contact() {
  const messageRef = React.useRef(null);
 

  return (
    <Element id="kontakt">
      <section
        id="contact"
        className={`contact-sec section-padding ${styles.mobileBigPaddingTop}`}
        style={{ backgroundImage: `url(/assets/img/generell/pattern.png)` }}
      >
        <div className="container">
          <div className="sec-head custom-font text-center">
            <h6>Ihr</h6>

            <h3>Kontakt</h3>

            <span className="tbg">Anfrage</span>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="form ">
                <Formik
                  initialValues={{
                    vorname: "",
                    nachname: "",
                    nachricht: "",
                    telefon: "",
                    email: "",
                    ziphoney: "",
                    emailhoney:"",
                    datenschutz: false,
                  }}
                  onSubmit={async (values, {resetForm}) => {
                    await sendMessage(values)
                      .then((response) => {
                        if(response.error){
                          Swal.fire({
                            title: "Fehler",
                            text: response.message,
                            icon: "error",
                    
                          })
                        } else {
                        Swal.fire({
                          title: "OK!",
                          text: response.message,
                          icon: "success",
                          timer: 2000
                        })
                        resetForm({values:''})
                        }
                      })
                      .catch((err) => {
                        Swal.fire({
                          title: "Fehler",
                          text: `Fehler beim sendern der Nachricht ${err}`,
                          icon: "error",
                          timer: 2000,
                        });
                      });
                  }}
                >
                  {({ errors, touched, values }) => (
                    <Form id="contact-form">
                      <div className="controls">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                id="vorname"
                                type="text"
                                name="vorname"
                                placeholder="Vorname"
                                required="required"
                              />
                            </div>
                            <div className="form-group d-none">
                              <Field
                                id="zip"
                                type="number"
                                name="ziphoney"
                                placeholder="Plz"
                              />
                            </div>
                            <div className="form-group d-none">
                              <Field
                                id="email"
                                type="text"
                                name="emailHoney"
                                placeholder="Email"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                id="nachname"
                                type="text"
                                name="nachname"
                                placeholder="Nachname"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                id="telefon"
                                type="text"
                                name="telefon"
                                placeholder="Telefonnummer"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <Field
                                id="form_email"
                                type="email"
                                name="email"
                                validate={validateEmail}
                                placeholder="Email"
                                required="required"
                              />
                              {errors.email && touched.email && (
                                <div>{errors.email}</div>
                              )}
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="form-group">
                              <Field
                                as="textarea"
                                id="form_message"
                                name="nachricht"
                                placeholder="Ihre Nachricht"
                                rows="4"
                                required="required"
                              />
                            </div>
                          </div>
                          <div className="d-flex justify-content-center m-auto p-2">
                            <div class="contact-area form-check">
                              <label>
                                <Field
                                  class="form-check-input unchecked"
                                  type="checkbox"
                                  name="datenschutz"
                                  
                                  id="flexCheckDefault"
                                  required="required"
                                />
                                Ich habe die Datenschutzerklärung zur Kenntnis
                                genommen und erkläre mich damit einverstanden{" "}
                                <a href="/datenschutz">Datenschutzerklärung</a>
                              </label>
                            </div>
                          </div>
                          <div className="col-12 mt-20">
                            <div className="text-center">
                              <button
                                type="submit"
                                className={`simple-btn cursor-pointer greenbtn`}
                              >
                                <span>Nachricht senden</span>
                              </button>
                            </div>
                          </div>
                          <div
                            className=" d-flex justify-content-center messages"
                            ref={messageRef}
                          ></div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Element>
  );
}
