import styles from "../styles/components/Error.module.css"

export default function Error(){
  return (
    <>
    <section>
      <div className={`${styles.bkg} d-flex justify-content-center m-auto`}>
        <div className={`d-flex justify-content-center m-auto ${styles.content}`}>
          <h1>404</h1>
          <h4>Seite nicht gefunden!</h4>
          <a class="btn btn-secondary" href="/" role="button">Zurück zur Homepage</a>
        </div>
      </div>
    </section>
    </>
  )
}