import React from "react";

export default function Picture(){
  return(
    <div className="container mt-50">
      <div className="row d-flex justify-content-center">
        <img src="assets/img/generell/Homepage Banner.png" alt=""></img>
      </div>
    </div>
  )
}