import styles from "../../styles/components/parts/GoogleReview.module.css"
import Star  from "./Star"

function isCertified(certified){
  if(certified){
    return(
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path fill="#197BFF" d="M6.757.236a.35.35 0 0 1 .486 0l1.106 1.07a.35.35 0 0 0 .329.089l1.493-.375a.35.35 0 0 1 .422.244l.422 1.48a.35.35 0 0 0 .24.24l1.481.423a.35.35 0 0 1 .244.422l-.375 1.493a.35.35 0 0 0 .088.329l1.071 1.106a.35.35 0 0 1 0 .486l-1.07 1.106a.35.35 0 0 0-.089.329l.375 1.493a.35.35 0 0 1-.244.422l-1.48.422a.35.35 0 0 0-.24.24l-.423 1.481a.35.35 0 0 1-.422.244l-1.493-.375a.35.35 0 0 0-.329.088l-1.106 1.071a.35.35 0 0 1-.486 0l-1.106-1.07a.35.35 0 0 0-.329-.089l-1.493.375a.35.35 0 0 1-.422-.244l-.422-1.48a.35.35 0 0 0-.24-.24l-1.481-.423a.35.35 0 0 1-.244-.422l.375-1.493a.35.35 0 0 0-.088-.329L.236 7.243a.35.35 0 0 1 0-.486l1.07-1.106a.35.35 0 0 0 .089-.329L1.02 3.829a.35.35 0 0 1 .244-.422l1.48-.422a.35.35 0 0 0 .24-.24l.423-1.481a.35.35 0 0 1 .422-.244l1.493.375a.35.35 0 0 0 .329-.088L6.757.236Z"></path><path fill="#fff" d="M9.065 4.85a.644.644 0 0 1 .899 0 .615.615 0 0 1 .053.823l-.053.059L6.48 9.15a.645.645 0 0 1-.84.052l-.06-.052-1.66-1.527a.616.616 0 0 1 0-.882.645.645 0 0 1 .84-.052l.06.052 1.21 1.086 3.034-2.978Z"></path></svg>
    )
  }
}

function createRatingBadgets(rating){
  var list = []
  for(let i = 0; i < rating; i++){
    list.push(<Star key={`${i}`}></Star>)
  }
  return list
}

/**
 * 
 * @param {*} props 
 * props:
 *  author,
 *  icon,
 *  certified,
 *  rating,
 *  review,
 *  createdAt
 * @returns 
 */

export default function GoogleReview(props){

  return(
    <div className={`${styles.googleReview}`}>
      <div className={`${styles.header} d-flex`}>
      <a className={`${styles.icon}`}>
        <div className={`${styles.reviewAvatarContainer}`}>
          <div className={`${styles.reviewAvatar}`}>
            <div className={`${styles.reviewAvatarBackground}`}>
            <img className="lDY1rd" alt={props.author} src={props.icon}></img>
            </div>
          </div>
        </div>
      </a>
      <div className={`${styles.reviewAuthor}`}>
        <a className={`${styles.authorContainer}`}>
        <p>{props.author}</p>

        <div className={`${styles.reviewBadget}`}>
        {isCertified(props.certified)}
        </div>
        </a>
        <div className={`${styles.reviewInfo}`}>
          <div>
            <div className={`${styles.reviewData}`}>
             <div className={`${styles.date}`}>{props.creationDate}</div>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className={`${styles.content} d-flex`}>
        <div className={`${styles.contentContainer}`}>
          <div className={`${styles.raitingContainer}`}>
            <div className={`${styles.raitingBar}`}>
            {createRatingBadgets(props.rating)}
            </div>
          </div>
          <div className={`${styles.reviewTextContainer}`}>
            <div className={`${styles.reviewText}`}>
              <p className="">{props.review}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}