import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Swal from "sweetalert2";
import { Checkbox } from "../parts/Checked";
import { handleFileUpload,  handleSubmitEvent } from "../../utils/handlers";

export default function ErfolgsgeschichtenEdit() {
  const [stories, setStories] = useState([]);
  const [clips, setClips] = useState([]);
  const [item, setItem] = useState([]);
  const [data, setData] = useState([]);
  var navigate = useNavigate();

  const { jwtToken } = useOutletContext("");
  const [file, setFile] = useState();
  const [filename, setFileName] = useState();

  const changeItem = (newItem) => () => {
    setItem(newItem);
  };

  const toogleOnScreen = () => {
    var tempdata = {...data}
    tempdata["showMovies"] = !tempdata["showMovies"]
    setData(tempdata)
  }

  const toogleOnMobile = () =>{
    var tempdata = {...data}
    tempdata["showOnMobile"] = !tempdata["showOnMobile"]
    setData(tempdata)
  }

  const uploadFile = () => {
    const url = `${process.env.REACT_APP_BACKEND}/admin/stories`;

    const formData = new FormData();
    formData.append("file", file);

    const headers = new Headers();

    headers.append("Authorization", "Bearer " + jwtToken);

    var requestOptions = {
      method: "POST",
      body: formData,
      headers: headers,
      credentials: "include",
    };
    handleFileUpload(url, requestOptions, ()=>{window.location.reload()})
  };

  async function removeFile() {
    const url = `${process.env.REACT_APP_BACKEND}/admin/stories`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    var requestOptions = {
      method: "DELETE",
      body: JSON.stringify(item),
      headers: headers,
    };

    handleFileUpload(url, requestOptions, ()=>{window.location.reload()});
  }

  const changeFile = (event) => {
    // the FileAPI return a Filelist form the input element
    const fileInput = document.getElementById("fileInput");
    const selectedFile = fileInput.files[0];
    setFile(selectedFile);
    setFileName(event.target.value);
  };

  useEffect(() => {
    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    fetch(`${process.env.REACT_APP_BACKEND}/stories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setData(data[0]);
        filter(data[0].stories);
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }, []);

  function filter(data) {
    var pics = data.filter((el) => {
      if (el.type.toLowerCase() !== "mov" && el.type.toLowerCase() !== "mp4") {
        return true;
      }
    });
    setStories(pics);

    var vids = data.filter((el) => {
      if (el.type.toLowerCase() === "mov" || el.type.toLowerCase() === "mp4") {
        return true;
      }
    });

    setClips(vids);
  }

  return (
    <>
      <div>Erfolgsgeschichten Edit</div>
      <div className="mt-20 mb-20">
        <h2 className="mt-50 ">Bilder</h2>
        <div className="row text-center text-lg-start">
          {stories &&
            stories.map((v) => (
              <div className="col-lg-3 col-md-4 col-6 p-0">
                <button
                  type="button"
                  onClick={changeItem(v)}
                  className={" h-100 " + (v.id === item.id ? "active" : "")}
                >
                  <img
                    className="img-fluid img-thumbnail"
                    src={v.link}
                    alt=""
                  />
                </button>
              </div>
            ))}
        </div>
        <div className="mt-20 mb-20">
          <h2 className="mt-50 ">Videos</h2>
          <div className="row text-center text-lg-start">
            {clips &&
              clips.map((v) => (
                <div className="col-lg-3 col-md-4 col-6 p-0">
                  <button
                    type="button"
                    onClick={changeItem(v)}
                    className={" h-100 " + (v.id === item.id ? "active" : "")}
                  >
                    <video width="200" preload="metadata">
                      <source
                        src={`${v.link}#t=0.1`}
                        type={`video/${v.type}`}
                      />
                    </video>
                  </button>
                </div>
              ))}
          </div>
        </div>

        <div className="form-check">
          <Checkbox 
            label="Videos anzeigen"
            value={data.showMovies ? true : false} 
            onChange={toogleOnScreen}
            />
        </div>
        <div className="form-check">
          <Checkbox
            label="Videos nur auf mobilen Endgeräten anzeigen"
            value={data.showOnMobile ? true : false}
            onChange={toogleOnMobile}
          />
        </div>
      </div>
      <div className="row mt-50">
        {<p>Aktuelle Datei: {item.link}</p>}

        <div className="input-group mt-3 mb-3">
          <input
            type="file"
            className="form-control"
            value={filename}
            onChange={changeFile}
            id="fileInput"
          />
        </div>
        <div className="d-flex">
          <button
            type="button"
            onClick={handleSubmitEvent(jwtToken, data, false, "/admin/stories", "PATCH", ()=>{window.location.reload()})}
            className="btn btn-success mt-2 fs-1btn-lg btn-block"
          >
            Änderungen Speichern
          </button>
          <button
            type="button"
            onClick={uploadFile}
            className="btn btn-warning ms-10 mt-2 fs-1btn-lg btn-block"
          >
            hochladen
          </button>
          <button
            type="button"
            className="btn btn-danger ms-10 mt-2 fs-1btn-lg btn-block"
            onClick={removeFile}
          >
            entfernen
          </button>
        </div>
      </div>
    </>
  );
}
