import Input from "../parts/Input";
import { useState, useEffect } from "react";
import TextArea from "../parts/TextArea";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import { handleSubmit } from "../../utils/handlers";
import Swal from "sweetalert2";

export default function SpecialSiteEdit() {
 
  const [content, setContent] = useState([])
  const { jwtToken } = useOutletContext("");
  const navigate = useNavigate();
 

  const handleChangeContent = () => (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setContent({
      ...content,
      [name]: value,
    });
  };

  useEffect(() => {

    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);

    let requestOptions = {
      method: "GET",
      headers: headers,
      credentials: "include",
    };
    fetch(`${process.env.REACT_APP_BACKEND}/admin/special`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          Swal.fire({
            title: "Fehler!",
            text: data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          setContent(data[0]);
        }
      })
      .catch((error) => {
       console.log(error)
      });
  }, []);
  return (
    <>
      <div>
        <h1>Workshop</h1>
      </div>
      <div className="container">
        <div className="col">
          <div>
            <h3>Inhalt</h3>
          </div>
          <Input
            title={"Youtube link"}
            className={"form-control"}
            type={"text"}
            name={"image"}
            value={content && content.image}
            onChange={handleChangeContent("image")}
          ></Input>
          <TextArea
            title={"Aktueller Inhalt"}
            name={"content"}
            value={content && content.content}
            rows={"10"}
            onChange={handleChangeContent("content")}
          />
          <div className="mt-10 mb-100 d-flex">
          <button
            type="button"
            className="btn btn-success mt-2 fs-1btn-lg btn-block"
            onClick={()=>{handleSubmit(jwtToken, content, false, "/admin/special", "POST", () =>{window.location.reload()})}}
          >
            Änderungen Speichern
          </button>
          <button
            type="button"
            onClick=""
            className="btn btn-danger ms-10 mt-2 fs-1btn-lg btn-block"
          >
            Abbrechen
          </button>
        </div>
      </div>
      </div>
    </>
  );
}
