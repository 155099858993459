import React from "react";

import styles from '../styles/components/CallToAction.module.css';
import { scroller} from 'react-scroll';


export default function CallToAction(props){
  function scrollTo(target){
    scroller.scrollTo(target, {
      duration: 700,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  }

  return (
    <section
      className={`call-action mt-80`}
      style={{ backgroundImage: `url("/assets/img/generell/pattern.png")` }}
    >
      <div className={`container ${styles.pos_relative}`}>
      <div className={`row valign mb-40`}>
      <h3 className={`${styles.clRose} ps-10 rose`}>Lass uns sprechen</h3>
      </div>
      
      <div className={`row`}>
      <div className={`col-md-2 col-lg-4 ${styles.mobileLargeRow} `}>
        <div className={`${styles.bgImg1}`}></div>
      </div>
      <div className="col-md-10 col-lg-8 justify-content-center m-auto">
        <p className={`fs-3 pt-4 m-auto txt ${styles.pt}`}> {props.text}</p>
          <ul className={`${styles.mobileMinMarginTop} ${styles.stars} mt-30 fs-3 mt-10`}>
            {props.bulletpoints && props.bulletpoints.map(bp=>(
              <li key={bp}>{bp}</li>
            ))}
          </ul>
        </div>
      </div>


      <div className={`row h-250 ${styles.callToAction}`}>
      <div className="col valign d-flex text-center justify-content-center">
          <div className={`sm-mb30 ${styles.mobilePadding}`}>
          <a href="https://calendly.com/annavoncanstein/ks"  target="_blank" rel="noreferrer"
            className={`simple-btn greenbtn mb-5`}
          >
          <span><h6>Kostenloses Erstgespräch</h6></span>
          </a>
          <h4 className="rose">{props.text2}</h4>   
        <h5>{props.text3}</h5>
        
        </div>
        </div>
        </div>
      </div>
    </section>
  );
};
