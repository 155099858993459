import React from "react";
import styles from "../../styles/components/parts/JumboBanner.module.css"
import { isOnMobile } from "../../utils/helperFunctions";

function getVideo(props){
  if(isOnMobile()){
    return <video width="320" height="320" src={`${props.link}#t=0.1`} controls type="video/mp5" />
  } else {
    return <video width="920" height="420" src={`${props.link}#t=0.1`} controls  type="video/mp4"/>
  }
}

function JumboBanner(props){
  return(
    <>
      <div className="row justify-content-center mt-2 mb-2 ">
        <div className={`${styles.jumbowrapper}`}>
          <div className="col justify-content-center text-center">
            {getVideo(props.props)}
          </div>
        </div>
      </div>
    </>
  )
}

export default JumboBanner