
import React from "react";
import {useState, useEffect} from "react"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import JumboBanner from "./parts/JumboBanner";
import { isOnMobile } from "../utils/helperFunctions";


function createLayout(pics, clips, config){
  var includeClips = false
  var layout = []
  var pices = pics.length
  if(config && (config.showMovies || isOnMobile() && config.showOnMobile)){
    var pices = pics.length / clips.length
    includeClips = true
  }
  
  var partions = 1
  clips.map((clip) =>{
    if (partions === clips.length){
      var currentPics = pics
    } else {
      var currentPics = pics.splice(0, pices)
    }
    partions = partions + 1
    layout.push(
      <>
      <div key={clip.id} className="container">
      <ResponsiveMasonry columnsCountBreakPoints={{ 500: 1, 900: 3 }}>
      <Masonry gutter="50px">
        {currentPics.map( pic => (
          <div className="my-border">
            <img src={pic.link}></img>
          </div>
        ))}
      </Masonry>
    </ResponsiveMasonry>
    </div>
    {includeClips && <JumboBanner props={clip}></JumboBanner>}
    </>
    )
  })

  return (
    layout
  )
}

const StoriesSummary = () => {
  const [stories, setStories] = useState([])
  const [clips, setClips] = useState([])
  const [config, setConfig] = useState([])

  function filter(data){
    var pics = data.filter((el) => {
      if(el.type.toLowerCase() !== "mov" &&  el.type.toLowerCase() !== "mp4"){
        return true
      }
    })
    setStories(pics)
    
    var vids = data.filter((el) =>{
      if (el.type === "mov" || el.type === "mp4"){
        return true
      }
    })

    setClips(vids)
  }

  useEffect( () => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    fetch(`${process.env.REACT_APP_BACKEND}/stories`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
            filter(data[0].stories)
            setConfig(({...config,
              "showMovies": data[0].showMovies,
              "showOnMobile": data[0].showOnMobile,
            }))
        })
        .catch((error)=>{
            console.log(error)
        })

  },[]);
 

  return (
    <section className={`blog-grid section-padding`}>
        <div className="sec-head custom-font text-center">
          <h6 className=" fadeIn">
            Erfolgsgeschichten
          </h6>
            <h3 className=" words chars">
              Resultate
            </h3>
          <span className="tbg">Stories</span>
        </div>
      
        {createLayout(stories, clips, config )}
        
    </section>
  );
};

export default StoriesSummary;
