import { useEffect, useState } from "react"
import styles from "../../styles/components/ReviewEdit.module.css"
import Input from "../parts/Input"
import Swal from 'sweetalert2'
import Select from "../parts/Select"
import TextArea from "../parts/TextArea"
import { useOutletContext, useNavigate } from "react-router-dom"
import { handleSubmit, handleSubmitEvent } from "../../utils/handlers"

export default function ReviewEdit(){
  const navigate = useNavigate();
  const [reviews, setReviews] = useState([])
  const [selected, setSelected] = useState(0)
  const {jwtToken} = useOutletContext();

  const changeSelect = (index) => () => {
    setSelected(index)
  }

  const handleChange = (name) => (event) => {
    let newReviews = [...reviews]
    newReviews[selected] = {
      ...newReviews[selected],
      [name]: name === "rating" ? parseInt(event.target.value) : event.target.value
    }
    setReviews(
      newReviews
    )
  }

  const removeItem = (index) => () => {
    Swal.fire({
      title: "Blog Beitrag löschen?",
      text: "Möchtest du den Blog Beitrag wirklich löschen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: "Ja! Löschen.",
      cancelButtonText: "Nein"
    }).then((result) =>{
      if(result.isConfirmed){
        let tempArray = [...reviews]
        tempArray.splice(index, 1)
        handleSubmit(jwtToken, tempArray, true, "/admin/reviews", "PATCH", ()=>{window.location.reload()})
      }
    })
  }

  const addItem = () => {
    let tempReviews = [...reviews]
    tempReviews.push({
      author:" ",
      icon:"",
      creationDate:"",
      certified: true,
      rating:1,
      review:"",
    })
    setReviews(tempReviews)
    setSelected(reviews.length)
  }

  useEffect(()=>{

    if (jwtToken === "") {
      navigate("/admin/");
      return
    }

    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    fetch(`${process.env.REACT_APP_BACKEND}/google_reviews`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
          setReviews(data)
        })
        .catch((error)=>{
          Swal.fire({
            title: "Fehler!",
            text: error,
            icon: "error",
            confirmButtonText: "OK",
          });
        })
    }, [])

  return (
    <>
      <div><h1>Google Reviews anpassen</h1></div>
      <div className="container">
        <div className="row mb-4">
          <div className="col">
          <h3>Bewertungen {reviews.length}</h3>
            <div className={`list ${styles.limitList}`}>
              <ul className="list-group text-dark">
              {reviews.map((review, index)=>{
                return(
                  <div className="d-flex justify-content-between">
                  <button type="button" key={`${index}`} onClick={changeSelect(index)} className={"list-group-item w-100 d-flex justify-content-between " + (index === selected ? "active" : "")} >
                    {review.author}
                  
                  </button>
                  <button type="button" onClick={removeItem(index)} className="btn m-2 btn-danger" data-toggle="button" aria-pressed="false">
                     <i className="fa fa-minus"></i>
                     </button>
                     </div>
                )
              })}
              </ul>
              
            </div>
            <button type="button" onClick={addItem} className="btn ms-10 mt-20 mb-20 btn-primary" data-toggle="button" aria-pressed="false" >
               <i className="fa fa-plus"></i>
              </button>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmitEvent(jwtToken, reviews, true, "/admin/reviews", "PATCH", ()=>{window.location.reload()})}>
      <Input
      title={"Author"}
      className={"form-control"}
      type={"text"}
      value={reviews.length > 0 &&  reviews[selected].author}
      onChange={handleChange("author")}
      ></Input>
      <Input
      title={"Author Icon"}
      className={"form-control"}
      type={"text"}
      value={reviews.length > 0 && reviews[selected].icon}
      onChange={handleChange("icon")}
      ></Input>
      <Select
      title={"Bewertung"}
      values={[1,2,3,4,5]}
      name={"bewertung"}
      current={reviews.length > 0 && reviews[selected].rating - 1}
      onChange={handleChange("rating")}
      ></Select>
      <Input
      title={"Erstellt am (Text)"}
      className={"form-control"}
      type={"text"}
      value={reviews.length > 0 && reviews[selected].creationDate}
      onChange={handleChange("creationDate")}
      ></Input>
      <TextArea
          title={"Bewertungsschreiben"}
          name={"quote_text"}
          value={reviews.length > 0 && reviews[selected].review}
          rows={"5"}
          onChange={handleChange("review")}
        />
      <div>
      <button className="btn btn-primary m-3">Speichern</button>
      <a href="/admin/reviews" className="btn btn-danger ms-2">Abbrechen</a>
      </div>
      </form>
    </>
  )
}