import React from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect, useRef } from "react";
import AktuellesSummary from "../components/AktuellesSummary";


const Beitreage = () => {
  const navbarRef = useRef(null);
  
  useEffect(() => {
    var navbar = navbarRef.current;
    if (window.scrollY > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <>
      <Navbar id={navbarRef} assets="./assets"/>
      <section className="page-header">
        <div className="container">
          <div className="row mt-150">
            <div className="col-lg-7 col-md-9">
            </div>
          </div>
        </div>
      </section>
      <AktuellesSummary/>
      <Footer showButton = {true}/>
      </>
  );
};

export default Beitreage;
