import { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Alert from "../components/Alerts";
import Swal from "sweetalert2";

function Admin() {
  const [jwtToken, setJwtToken] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertClassName, setAlertClassName] = useState("d-none");
  const [destination, setDestination] = useState("");
  const [tickInterval, setTickInterval] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleRefresh = useCallback(
    (status) => {
      if (status) {
        let i = setInterval(() => {
          const requestOptions = {
            method: "GET",
            credentials: "include",
          };

          fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
              if (data.access_token) {
                setJwtToken(data.access_token);
              }
            })
            .catch((error) => {
              console.log("user is not logged in");
            });
        }, 600000);
        setTickInterval(i);
      } else {
        setTickInterval(null);
        clearInterval(tickInterval);
      }
    },
    [tickInterval]
  );

  function parse(path) {
    const parts = path.split("/");
    setDestination(parts[parts.length - 1]);
  }

  const logOut = () => {
    const requestOptinos = {
      method: "GET",
      credentials: "include",
    };
    fetch(`${process.env.REACT_APP_BACKEND}/logout`, requestOptinos)
      .catch((err) => {
        console.log("Error logging out");
      })
      .finally(() => {
        setJwtToken("");
        toggleRefresh(false);
      });
    navigate("/admin");
  };

  function refresh() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/refresh`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.access_token) {
          setJwtToken(data.access_token);
          toggleRefreshHook(true);
          navigate("/admin/landingpage");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const toggleRefreshHook = useCallback(toggleRefresh, [tickInterval]);

  useEffect(() => {
    if (jwtToken === "") {
      refresh();
    }
    parse(location.pathname);
  }, [jwtToken, location, toggleRefreshHook]);

  return (
    <>
      <div className="container">
        <div className="row d-flex justify-content-center mt-10">
          <div className="col d-flex justify-content-end">
            {jwtToken === "" ? (
              <Link to="/admin/">
                <button className="btn btn-primary" type="submit">
                  Einloggen
                </button>
              </Link>
            ) : (
              <a href="/admin/" onClick={logOut}>
                <button className="btn btn-danger" type="submit">
                  Ausloggen
                </button>
              </a>
            )}
          </div>
          <hr className="mb-3"></hr>
        </div>

        <div className="row">
          {jwtToken !== "" && (
            <>
              <div className="col-md-3">
                <nav>
                  <div className="list-group">
                    <Link
                      to="/admin/landingpage"
                      className={
                        "list-group-item list-group-item-action " +
                        (destination === "landingpage" ? "active" : "")
                      }
                    >
                      Landing Page
                    </Link>
                    <Link
                      to="/admin/reviews"
                      className={
                        "list-group-item list-group-item-action " +
                        (destination === "reviews" ? "active" : "")
                      }
                    >
                      Google Reviews
                    </Link>
                    <Link
                      to="/admin/erfolgsgeschichten"
                      className={
                        "list-group-item list-group-item-action " +
                        (destination === "erfolgsgeschichten" ? "active" : "")
                      }
                    >
                      Erfolgsgeschichten
                    </Link>
                    <Link
                      to="/admin/aktuelles"
                      className={`list-group-item list-group-item-action ${
                        destination === "aktuelles" ? "active" : ""
                      }`}
                    >
                      Aktuelles
                    </Link>
                    <Link
                      to="/admin/blog"
                      className={`list-group-item list-group-item-action ${
                        destination === "blog" ? "active" : ""
                      }`}
                    >
                      Blog
                    </Link>
                    <Link
                      to="/admin/impressum"
                      className={`list-group-item list-group-item-action ${
                        destination === "impressum" ? "active" : ""
                      }`}
                    >
                      Impressum und Datenschutz
                    </Link>
                    <Link
                      to="/admin/special"
                      className={`list-group-item list-group-item-action ${
                        destination === "special" ? "active" : ""
                      }`}
                    >
                      Workshop
                    </Link>
                    <Link
                      to="/admin/categories"
                      className={`list-group-item list-group-item-action ${
                        destination === "categories" ? "active" : ""
                      }`}
                    >
                      Kategorie
                    </Link>
                  </div>
                </nav>
              </div>
            </>
          )}
          <div className={jwtToken !== "" ? "col-md-9" : "col"}>
            <Alert message={alertMessage} className={alertClassName} />
            <Outlet
              context={{
                jwtToken,
                setJwtToken,
                setAlertClassName,
                setAlertMessage,
                toggleRefresh,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Admin;
