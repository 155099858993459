import styles from '../styles/components/Navbar.module.css';
import { handleMobileDropdown } from '../utils/navbarHelpers';
import {useLocation, Link} from "react-router-dom"
import React  from 'react';
import { useEffect } from 'react';
import { isOnMobile } from '../utils/helperFunctions';

export default function Navbar(props){
   
    const location = useLocation();
    useEffect(() => {
      const elementId = location.hash.substring(1); // Remove the leading '#' from the URL hash

      if(isOnMobile()){
        handleMobileDropdown(false);
      }
    }, [location]);  


    return(
        <nav ref={props.id}className="navbar navbar-expand-lg change">
            <div className="container">
                    <a href={"/#home"} className={styles.logo}>
                        <img className={styles.mobileImg} alt="image" src={`${props.assets}/img/logo/Logo_ohne Schrift_gold_transparent.png`} width="300" height="300"></img>
                    </a>
                <button
                    className={`navbar-toggler ${styles.navButton}`}
                    type="button"
                    onClick={()=>{handleMobileDropdown(true)}}
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    >
                   <span className="icon-bar">
                    <i className="fas fa-bars"></i>
                    </span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                    <li className={`${styles.navItem} nav-item`}>
                        <a href={"/#home"} className="nav-link">Home</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                     <a href={"/#angebot"} className="nav-link">Angebot</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href={"/#feedback"} className="nav-link">Feedback</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href={"/#qualifikationen"} className="nav-link">Qualifikationen</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href={"/#kontakt"} className="nav-link">Kontakt</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href={"/stories"} className="nav-link">Erfolgsgeschichten</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href={"/aktuelles"} className="nav-link">Aktuelles</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href={"/beitreage"} className="nav-link">Blog</a>
                    </li>
                    <li className={`${styles.navItem} nav-item`}>
                    <a href="https://www.elopage.com/s/annavoncanstein" target="_blank" rel="noreferrer" className="nav-link">Shop</a>
                    </li>
                    </ul>
                </div>
            </div>
        </nav>
    )

}
