import { useEffect, useState } from "react"
import { useOutletContext, useNavigate } from "react-router-dom"
import TextArea from "../parts/TextArea"
import { handleSubmitEvent } from "../../utils/handlers"

export default function ImpressumEdit(){
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const { jwtToken } = useOutletContext("");

  const handleChange = (event) => {
    console.log("change")
    var tempdata = event.target.value
    setData({
      ...data,
      content: tempdata
    })
  }

  useEffect(()=>{

    if (jwtToken === "") {
      navigate("/admin/");
      return;
    }

    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    fetch(`${process.env.REACT_APP_BACKEND}/impressum`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
            setData(data[0])
        })
        .catch((error)=>{
            console.log(error)
        }
      )  
  }, [])
  return (
    <>
      <div className="container">
        <div className="row">
        <div className="col">
          <div><h1>Anpassung Impressum</h1></div>
          <div><p>Hier kann das Impressum angepasst werden! Bitte die <b>Markdown Syntax</b> werden</p>
       </div>
          <TextArea
            title={"Aktuelle Inhalt"}
            name={"Impressum Content"}
            value={data.content}
            rows={"20"}
            onChange={handleChange}
          />
        </div>
        </div>
        <button type="submit" onClick={handleSubmitEvent(jwtToken, data, false, "/admin/impressum", "PATCH", ()=>{window.location.reload()})} className="btn btn-primary m-3">
            Speichern
          </button>
      </div>

    </>
  )
}