import React from "react";
import BlogDetails from "../components/BeitragDetails";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { useEffect, useRef } from "react";
import StoriesSummary from "../components/StoriesSummary";

const Stories = () => {

  const navbarRef = useRef(null);
  
  useEffect(() => {
    var navbar = navbarRef.current;
    if (window.scrollY > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <>
      <Navbar id={navbarRef} assets="../assets"/>
      <section className="page-header blg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-9">
              <div className="mt-100 text-center">

              </div>
            </div>
          </div>
        </div>
      </section>
      <StoriesSummary />
      <Footer showButton={true}/>
      </>
  );
};

export default Stories;
