import Swal from 'sweetalert2'

export function handleFileUpload(url, requestOptions, cb) {
  return (
    fetch(url, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      Swal.fire({
        position: "bottom-end",
        icon: data.error ? "error" : "success",
        title: data.error ? "Fehler" : "",
        text: data.message,
        showConfirmButton: false,
        timer: 2500,
        willClose: ()=>{cb && !data.error && cb()}
      })
    })
    .catch((error) => {
      Swal.fire({
        title: "Fehler!",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    })
  )
};

export const handleSubmitEvent = (jwtToken, data, isArr, destination, method, cb) => (event) => {
  event.preventDefault();
  handleSubmit(jwtToken, data, isArr, destination, method, cb)
}

export const handleSubmit = (jwtToken, data, isArr, destination, method, cb) =>{
  
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", "Bearer " + jwtToken);
  var m = method
  let requestBody

  if (isArr) {
    requestBody = data 
  } else {
    requestBody = []
    requestBody.push(data);
  }
  let requestOptions = {
    body: JSON.stringify(requestBody),
    method: m,
    headers: headers,
    credentials: "include",
  };

 return fetch(`${process.env.REACT_APP_BACKEND}${destination}`, requestOptions)
    .then((response) => response.json())
    .then((data) => {
      if (data && data.error) {
        Swal.fire({
          title: "Fehler!",
          text: data.message,
          icon: "error",
          confirmButtonText: "OK"
        });
      } else {
        Swal.fire({
          position: "bottom-end",
          icon: "success",
          title: data.message,
          showConfirmButton: false,
          timer: 2000,
          willClose: () =>{cb && cb()}
        })
      }
    })
    .catch((error) => {
      Swal.fire({
        title: "Fehler!",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
      });
    });
};
