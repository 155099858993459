
import React from "react";
import { useLocation, Link, useParams } from "react-router-dom";
import styles from "../styles/components/BeitragDetails.module.css"
import { useState, useEffect } from "react";
import Markdown from "react-markdown"

const YoutubeEmbed = ({ embedId }) => (
  <div className={`${styles.video}`}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?rel=0&autoplay=1`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

function getTopics(post){
  var topics = []
  if(post.topics){
    topics = Object.values(post.topics)
  }
  return topics
}

function getSocialIcons(post){
  var icons = []
  if (post.instagram !== "") {
    icons.push(
      <a href={post.instagram} target="_blank" rel="noreferrer noopener" className="icon" >
        <i className="fab fa-instagram"></i>
      </a>
    )
  }
  if (post.linkedin !== ""){
    icons.push(
      <a href={post.linkedin} target="_blank" rel="noreferrer noopener" className="icon">
        <i className="fab fa-linkedin-in"></i>
      </a>
    )
  }

  if(post.mail !== "") {
   icons.push(
    <a href={post.facebook} target="_blank" rel="noreferrer noopener" className="icon">
        <i className="fab fa fa-envelope"></i>
      </a>
   )
  }

  if(post.youtube !== ""){
    icons.push(
      <a href={post.youtube} target="_blank" rel="noreferrer noopener" className="icon">
        <i className="fab fa-youtube"></i>
      </a>
    )
  }
  return icons
}

function getVidId(url){
  const words = url.split('/')
  const id = words.pop()
  return id
}

function imgOrVid(post){
  var el 
  if (post.video && post.video !== "") {
    var id = getVidId(post.video)
    el = <div className="img">
      <YoutubeEmbed embedId={id}/>
    </div>
  } else {
    el = <div className="img">
      <img src={post.image}></img>
    </div>
  }
  return el
}
const BeitragDetails = () => {
  const {id} = useParams()
  const [post, setPost] = useState({})
  
  const location = useLocation();
    useEffect(() => {
      const elementId = location.hash.substring(1); // Remove the leading '#' from the URL hash
      scrollToElement(elementId);
    }, [location]);  

    const scrollToElement = (elementId) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };

  useEffect( () => {
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    const result = fetch(`${process.env.REACT_APP_BACKEND}/postings/${id}`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
            setPost(data)
        })
        .catch((error)=>{
            console.log(error)
        }
      )
  },[]);
 

  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div id="Post" className="post">
            <h1 className="mt-4 gold">{post.title}</h1>
            <div className="ms-50 mb-10">
              {getTopics(post).map((topic) =>(
                  <span className="rose m-50 p-2" key={topic.id}>{topic.name} </span>
              ))}
            </div>
              {post && imgOrVid(post)}
              <div className="content pt-20">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont blogcontent">
                      <Markdown>{post.content_text}</Markdown>  
                      <div className="share-info">
                        <div className="tags">
                        {getTopics(post).map(topic =>{
                          <a href="#">{topic},</a>
                        })}
                        </div>
                      </div>
                    </div>
                    <div className="author">
                      <div className="author-img">
                        <img src={`${post.icon}`} alt="..."/>
                      </div>
                      <div className="info">
                        <h6>
                          <span>author :</span> {post.author}
                        </h6>
                        <p>
                         {post.about}
                        </p>
                        <div className="social">
                          {getSocialIcons(post)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pagination">
                <span>
                  <a href={post.prev !== "" ? `/beitrag/${post.prev}` : `/beitrag/${post.id}`}>Vorheriger Beitrag</a>
                </span>
                <span className="icon">
                  <Link to={"/beitreage"}>
                    <a>
                      <i className="fas fa-th-large"></i>
                    </a>
                  </Link>
                </span>
                <span className="text-right">
                  <a href={post.next !== "" ? `/beitrag/${post.next}` : `/beitrag/${post.id}`}>Nächster Beitrag</a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeitragDetails;
