import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import styles from "../styles/components/BeitragSummary.module.css";
import 'bootstrap/dist/css/bootstrap.css';
import Swal from 'sweetalert2'

const YoutubeEmbed = ({ embedId }) => (
  <div className={`${styles.video}`}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?rel=0`}
      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      title="Embedded youtube"
    />
  </div>
);

function getVidId(url) {
  const words = url.split("/");
  const id = words.pop();
  return id;
}

function getImage(post, vid) {
  if (post.show_pic) {
    return <img src={post.image}></img>;
  } else if (post.thumbnail !== "" && vid) {
    return <YoutubeEmbed embedId={getVidId(post.video)} />;
  } else if (post.thumbnail !== "") {
    return <img src={post.thumbnail}></img>;
  } else {
    return <img></img>;
  }
}

function spinner(){
  return (
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
  )
}

function searchResultsSpinner(){
  return (
    <div className={`d-flex justify-content-center ${styles.searchResults} text-dark`}>
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
)
}

function getTopics(post) {
  var topics = [];
  if (post.topics) {
    topics = Object.values(post.topics);
  }
  console.log(topics);
  return topics;
}


const BeitragSummary = () => {
  const [searching, setSearching] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const [postings, setPostings] = useState();
  const [categoris, setCategories] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [displayResults, setDisplayResults] = useState(false);
  const [searchString, setSearchString] = useState("");

  const highlightSearch =(toHighlight) =>{
    var found = toHighlight.toLowerCase().indexOf(searchString.toLowerCase())
    var searchStringLen = searchString.length
    var el
    if(found === 0){
      console.log(found)
      var firstPart = toHighlight.substring(found, found + searchStringLen)
      var secondPart = toHighlight.substring(found + searchStringLen , toHighlight.length )
      el = <span><strong>{firstPart}</strong>{secondPart}</span>
    } else {
      console.log(found)
      var firstPart = toHighlight.substring(0, found)
      var secondPart = toHighlight.substring(found, found + searchStringLen)
      var thirdPart = toHighlight.substring(found + searchStringLen, toHighlight.length )
      el = <span>{firstPart}<strong>{secondPart}</strong>{thirdPart}</span>
    }
    return(
      el
    )
  }
  const findResults = (event) => {
    if (event.target.value.length <= 1){
      setDisplayResults(false)
      setSearching (false)
    } else if(!searching)
    {
      setSearching(true)
      const headers = new Headers();
      const titleSearch = event.target.value;
      setSearchString(titleSearch)
      let query = "?title=" + titleSearch;
      headers.append("Content-Type", "application/json");
  
      const requestOptions = {
        method: "GET",
        headers: headers,
      };
  
      fetch(`${process.env.REACT_APP_BACKEND}/postings${query}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setSearchResults(data)
          setSearching(false)
          setDisplayResults(true)
        })
        .catch((error) => {
          Swal.fire({
            title: "Fehler!",
            text: error,
            icon: "error",
          });
          setSearching (false)
        });
    }
  }

  const displaySearchResults = () =>{
    if (!searchResults || searchResults.length === 0){
      return(
        <div className={`d-flex justify-content-start ${styles.searchResults}`}>
          <ul className="m-0">
            <li className="p-2"><b>keine</b> Ergebnisse gefunden</li>
          </ul>
      </div>
      )
    } else {
      return(
      <div className={`d-flex justify-content-start ${styles.searchResults}`}>
        <ul className="m-0">
           {searchResults.map((result) => {
            return(
            <li className={`p-2 ${styles.roseOnHover}`}> <Link to={`/beitrag/${result.id}#Post`}>{highlightSearch(result.title)}</Link></li>
            )
            })}
        </ul>
      </div>
      )
    }
  }

  useEffect(() => {
    const headers = new Headers();
    const categorieSearch = searchParams.get("categorie");
    let query = "";
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    if (categorieSearch !== "") {
      query = "?categorie=" + categorieSearch;
    }

    fetch(`${process.env.REACT_APP_BACKEND}/postings${query}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setPostings(data);
      })
      .catch((error) => {
        console.log(error);
      });

    fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className={`blog blog-grid `}>
      <div className="container">
        <div className="sec-head custom-font text-center">
          <h6 className=" fadeIn">Videos</h6>
          <h3 className=" words chars">Informationen</h3>
          <span className="tbg">Blog</span>
        </div>
        <div className="row">
          <div className="col-lg-8 entries">
            {postings &&
              postings.map((posting) => {
                return (
                  posting.visible && (
                    <article className="entry">
                      <div className="entry-img">{getImage(posting, true)}</div>
                      <h2 className="entry-title">
                        <Link to={`/beitrag/${posting.id}#Post`}>
                          {posting.title}
                        </Link>
                      </h2>

                      <div className="entry-content">
                        <p>{posting.summary}</p>
                        <div className="read-more">
                          <Link to={`/beitrag/${posting.id}#Post`}>
                            Mehr Erfahren
                          </Link>
                        </div>
                      </div>
                    </article>
                  )
                );
              })}
            <div className="blog-pagination">
              <ul className="justify-content-center">
                <li className="active">
                  <a href="#">1</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar">
              <h3 className="sidebar-title">Suche</h3>
              <div className={`sidebar-item search-form ${styles.searchForm}`}>
                <form action="">
                  <input type="text" placeholder="nach Title suche" onChange={findResults} />
                  <button type="button">
                  {searching && searching ? spinner() : <i className="fa fa-search"></i> }
                  </button>
                </form>{
                  displayResults ? 
                  (searching ? searchResultsSpinner() : displaySearchResults()) : ""
                }
              </div>

              <h3 className="sidebar-title">Kategorien</h3>
              <div className="sidebar-item categories">
                <ul>
                  {categoris &&
                    categoris.map((cat, index) => {
                      return (
                        <li>
                          <a
                            key={index}
                            href={`/beitreage?categorie=${cat.name}`}
                          >
                            {cat.name} <span>({cat.quantity})</span>
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </div>

              <h3 className="sidebar-title">Aktuelle Beiträge</h3>
              <div className="sidebar-item recent-posts">
                {postings &&
                  postings.map((posting) => (
                    <div className="post-item clearfix">
                      {getImage(posting, false)}
                      <h4>
                        <a href={`/beitrag/${posting.id}#Post`}>
                          {posting.title}
                        </a>
                      </h4>
                      <time datetime="2020-01-01">{posting.creation}</time>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BeitragSummary;
