
import styles from '../styles/components/Offer.module.css';
import {Element} from 'react-scroll';

export default function Offer(props){
    return(
    <Element id="angebot">
    <div id="service" className={`container ${styles.bigMobilePadding}`}>

        <div className={`sec-head custom-font text-center mt-50`}>
          <h6 className='gold'> Mein </h6>
          <h3>Angebot</h3>
          <span className={`${styles.bgMobile} ${styles.smallLineHeight} tbg `}>Stop People Pleasing</span>
        </div>

        <div className="row justify-content-center">
            <div className="col-lg-9 col-md-9">
            <div className={`caption center ${styles.service} `}>
                  <h1 className="p-1 mt-10 gold">{props.title}</h1>
                  <h6 className={`${styles.mobileHeading} mt-10 mb-15 rose`}>{props.untertitle}</h6>
                  <p className="txt mb-5">{props.text}</p>
                  <p className='txt'>{props.text_2}</p>
            </div>
            </div>
        </div>
    </div>
    </Element>
    )
}
