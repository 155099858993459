import { forwardRef } from "react";

const Select = forwardRef((props, ref) => {
return (
  <label>
  <p className="">{props.title}</p>
    <select onChange={props.onChange} className="form-select form-select-sm" aria-label=".form-select-sm example" name={props.name}>
      {props.values.map((value, index)=>{
        if(index === props.current)
          return(
            <option selected="true" value={index}>{value}</option>
          )
        else 
        return(
        <option value={index + 1}>{value}</option>
        )
      })} 
  </select>
  </label>
  )
})

export default Select;