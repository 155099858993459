import styles from '../styles/components/Special.module.css'
import Markdown from "react-markdown"


const YoutubeEmbed = ({ embedId }) => (
  <div className={`${styles.video} mb-20 d-flex justify-content-center`}>
    <iframe
      src={`https://www.youtube.com/embed/${embedId}?rel=0&autoplay=1`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

export default function SpecialSummary(props){

  function getVidId(url){
    const words = url.split('/')
    const id = words.pop()
    return id
  }
  
  function imgOrVid(post){
    var el 
    var id = getVidId(post.image)
      el = <div className="img m-auto">
        <YoutubeEmbed embedId={id}/>
    </div>
    return el
  }

  return(
    <div className="container m-auto  mt-50 mb-50">
    
      <div className={`${styles.special} row h-100 d-flex justify-content-center`}>
      <div className="col w-80 m-auto">
      <Markdown>{props.content && props.content.content}</Markdown>
      {props.content && imgOrVid(props.content)}

      <div className={`sm-mb d-flex justify-content-center ${styles.mobilePadding}`}>
      <a
                href="https://calendly.com/annavoncanstein/ks"
                role="button"
                target="_blank"
                rel="noreferrer"
                className={`simple-btn cursor-pointer greenbtn mt-30`}
              >
                <span className={`${styles.smallOnMobile}`}>
                  Kostenloses Erstgespräch
                </span>
              </a>
    
        </div>
      </div>
    </div>
    </div>
  )
}