import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { handleSubmit } from "../../utils/handlers";
import Input from "../parts/Input";

export default function CategorieEdit() {
  const [categories, setCategories] = useState()
  const [categori, setCategorie] = useState()
  const { jwtToken } = useOutletContext("");
  useEffect(()=>{
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: headers,
    };
    fetch(`${process.env.REACT_APP_BACKEND}/categories`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function removeCategorie(id){
    Swal.fire({
      title: "Kategorie löschen?",
      text: "Möchtest du die Kategorie wirklich löschen",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ja! Löschen.",
      cancelButtonText: "Nein",
    })
      .then((result) => {
        if (result.isConfirmed) {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", "Bearer " + jwtToken);
   
    let requestOptions = {
      method: "DELETE",
      headers: headers,
      credentials: "include",
    };

    fetch(`${process.env.REACT_APP_BACKEND}/admin/categorie?id=${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.error) {
          Swal.fire({
            title: "Fehler!",
            text: data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            position: "bottom-end",
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            timer: 2000,
            willClose: () =>{window.location.reload()}
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler!",
          text: error,
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  }})}
 
  function addNewCategori(){
    let newCat = {
      "name":categori
    }
    handleSubmit(jwtToken, newCat, false, "/admin/categorie", "POST", () => {window.location.reload()})
  }

  const handleInputCategori = (event) =>{
    setCategorie(event.target.value)
  }

  const handleNewCategorie = (event) => {
    if (!categori || categori && categori.length <= 2 ){
      Swal.fire({
        title: "Fehler",
        text: "Neue Kategorie kann nicht leer sein",
        timer: 2000,
        icon: "warning"
      })
    }  else {
      Swal.fire({
        title: "Neue Kategorie erstellen",
        text: `Soll die neue Kategorie ${categori} erstellt werden?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Ja! erstellen.",
        cancelButtonText: "Nein"
      }).then((result) =>{
        if (result.isConfirmed){
          addNewCategori()
        } else {
          //nothing to do
        }
       }
      )
    }
  };

  const navigate = useNavigate()
  return (
    categories && 
    <>
      <div>
        <button className="mb-10 p-2 btn btn-primary" onClick={()=>{navigate(-1)}}>
          Zurück
        </button>
        <h3>Kategorien {categories.length}</h3>
            <div className={`list `}>
              <ul className="list-group text-dark">
                {categories.map((categorie, index) => {
                  return (
                    <div className="d-flex justify-content-between">
                      <button
                        type="button"
                        key={`${index}`}
                        className={
                          "list-group-item w-100 d-flex justify-content-between "
                        }
                      >
                        {categorie.name}
                      </button>
                      <button
                        type="button"
                        className="btn m-2 btn-danger"
                        data-toggle="button"
                        aria-pressed="false"
                        onClick={()=>{removeCategorie(categorie.id)}}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  );
                })}
              </ul>
            </div>
            <div className="d-flex">
            <Input
              title={"Neue Kategorie"}
              className={"form-control"}
              type={"text"}
              name={"video"}
              value={categori}
              onChange={handleInputCategori}
            />
            <button
              type="button"
              className={"btn ms-10 mt-20 btn-primary"}
              onClick={handleNewCategorie}
            >
              <i className="fa fa-plus"></i>
            </button>
          </div>
      </div>
    </>
  );
}
