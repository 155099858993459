import React from "react";
import styles from "../styles/components/Change.module.css";

export default function Change(props) {
  return (
    <section className={`services section-ptb`}>
      <div className="container">
        <div className="sec-head custom-font text-center">
          <h6>Nachhaltige</h6>
          <h3>Veränderung</h3>
          <span className="tbg">Wandel</span>
        </div>
        <div className="row">
          {props.graphics.map((item, index) => (
            <div
              className={` ${styles.mobileColMargin} col-6 col-lg-3`}
              key={item.id}
            >
              <div
                className={`item ${
                  index !== props.graphics.length - 1 ? "md-mb10" : ""
                } wow fadeInUp ${styles.mobileNoMargin}`}
                data-wow-delay={
                  item.id === 1 ? ".1s" : item.id === 2 ? ".3s" : ".5s"
                }
              >
                <img
                  className={styles.image}
                  src={props.images[item.imagePos]}
                  alt={item.title}
                  width="350"
                  height="350"
                ></img>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
