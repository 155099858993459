import React from "react";
import styles from "../styles/components/Newsletter.module.css"
import { useEffect } from "react";
export default function Newsletter(){

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://annavoncanstein.activehosted.com/f/embed.php?id=19";
    script.async = true;

    var el = document.getElementById("externalScript")
    el.append(script)

  }, []);
  
  return(
    <section className="mb-100">
        <div className="col text-center">
          <h5 className="mb-3">Verpasse keine Neuigkeiten! Hol dir inspirierende Tipps direkt in dein Postfach. </h5>
     
        <div className={`_form_19 adjustStyles`}></div><div id="externalScript"></div>
        </div>
    </section>
  )
}