import React from "react";
import styles from '../styles/components/Qualification.module.css';
import { Element } from 'react-scroll';
import { isOnMobile } from "../utils/helperFunctions";

export default function Qualification (props) {
  if(isOnMobile){
    var first = props.siegel.slice(0, props.siegel.length);
  } else {
    first = props.siegel.slice(0, props.siegel.length / 2);
    var second = props.siegel.slice(props.siegel.length / 2 , props.siegel.length);
  }

  var list = []
  let j = 0
  for(let i=0; i < props.bulletpoints.length; i++){
    var el = <li key={j++}className={styles.firstLevel}>{props.bulletpoints[i].head}
      <ul className={styles.innerList}>
        {props.bulletpoints[i].points.map(point =>(
          <li className="fs-18" key={j++}>{point}</li>
        ))}
      </ul>
    </li>
    list.push(el)
  }

  const showSiegel = () =>{
    var show = false
    if (!isOnMobile()){
      show = true
    } else if (isOnMobile() && props.showOnMobile){
      show = true
    }
    if (show === true){
      return(
        <div className="col-lg-5">
                <div>
                  <div className="row bord">
                    {first.map((item) => (
                      <div key={item.id} className={`col-md-6 col-6 brands
                      ${!item.href ? "noRef" : "hasRef"}`}>
                        <div
                          className="item wow fadeIn"
                          data-wow-delay={`${
                            item.id === 1
                              ? ".1"
                              : item.id === 2
                              ? ".3"
                              : item.id === 3
                              ? ".5"
                              : item.id === 4
                              ? ".5"
                              : ""
                          }s`}
                        >
                          <div className= {`img  ${styles.smallerImg}`}>
                              <img src={props.images[item.image]} width="350px" height="350px" alt="" />
                              <a
                                href={item.href}
                                className="link words chars"
                              >
                                {item.url}
                              </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
  
                  {!isOnMobile ?
                  (<div className="row">
                    {second.map((item) => (
                      <div
                        key={item.id}
                        className={`${
                          item.id === 3
                            ? "col-md-4 col-6 brands sm-mb30"
                            : item.id === 4
                            ? "col-md-4 col-6 brands sm-mb30"
                            : item.id === 5
                            ? "col-md-4 col-6 brands"
                            : item.id === 6
                            ? "col-md-4 col-6 brands"
                            : ""
                        }`}
                      >
                        <div
                          className="item wow fadeIn"
                          data-wow-delay={`${
                            item.id === 1
                              ? ".1"
                              : item.id === 2
                              ? ".3"
                              : item.id === 3
                              ? ".5"
                              : item.id === 4
                              ? ".5"
                              : ""
                          }s`}
                        >
                          <div className="img">
                              <img src={props.images[item.image]}  alt="" />
                              <a
                                href={item.href}
                                className="link words chars"
                              >
                                {item.url}
                              </a>
                            </div>
                        </div>
                      </div>
                    ))}
                  </div>)
                  :
                  (<div></div>)
                  }
                </div>
            </div>
      )
    }
  return
  }

  return (
    
    <Element id="qualifikationen">
      <section className={`clients mt-100 ${styles.mobileBigTopPadding}`}>
        <div className="container">
        <div className="sec-head custom-font text-center">
          <h6>Meine</h6>
          <h3>Qualifikationen</h3>
          <span className="tbg">Fachwissen</span>
        </div>
        <div className={`row ${styles.pushRowUp}`}>
          <div className={`${styles.text} col-lg-7 valign fs-3`}>
          <ul>
            {list}
          </ul>
          </div>
          {
            showSiegel()
          }
        </div>
        </div>
        </section>
    </Element>
  );
};
