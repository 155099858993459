import React from "react";
import {useState, useEffect} from 'react'
import {Link, useSearchParams, useNavigate} from "react-router-dom"
import Footer from "../components/Footer";
import styles from '../styles/components/Special.module.css'
import SpecialSummary from '../components/SpecialSummary'
import Swal from "sweetalert2";

const Special = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState()
  var navigate = useNavigate()

  useEffect(() => {
    const headers = new Headers();
    const siteKey = searchParams.get("sitekey");
    let query = "";
    headers.append("Content-Type", "application/json");

    const requestOptions = {
      method: "GET",
      headers: headers,
    };

    if (siteKey !== "") {
      query = "?sitekey=" + siteKey;
    }

    fetch(`${process.env.REACT_APP_BACKEND}/special${query}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if(data.error){
          Swal.fire({
            title: "Fehler",
            text: data.message,
            icon: "warning",
            confirmButtonText: "OK",
          }).then(() =>{
            navigate("/")
          }
          )
        }
        setData(data[0]);
      })
      .catch((error) => {
        Swal.fire({
          title: "Fehler",
          text: error.message,
          icon: "warning",
          confirmButtonText: "OK",
        })
      });
    }, []);

  return (
    <>
      <div className="container">
      <Link to={"/#Home"} className={styles.logo}>
        <img className={styles.mobileImg} alt="image" src={`assets/img/logo/Logo_ohne Schrift_gold_transparent.png`} width="300" height="300"></img>
      </Link>
        <div className="row">
          <div className="col mb-20 d-flex justify-content-center m-auto">
          <SpecialSummary content={data}></SpecialSummary>
          </div>
        </div>
      </div>
          <Footer showButton = {false} ></Footer>
    </>
  );
};

export default Special;
