import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Input from "../components/parts/Input";
import Swal from 'sweetalert2'

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { jwtToken } = useOutletContext();
    const { setJwtToken } = useOutletContext();
    const { setAlertClassName } = useOutletContext();
    const { setAlertMessage } = useOutletContext();
    const { toggleRefresh } = useOutletContext();

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        let payload = {
            email: email,
            password: password,
        }

        const requestOptions ={
            method: "POST",
            headers : {
                'Content-Type': 'application/json'
            },
            credentials: "include",
            body: JSON.stringify(payload),
        }

        fetch(`${process.env.REACT_APP_BACKEND}/authenticate`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            if(data.error){
                Swal.fire({
                    title: "Verweigert",
                    text: data.message,
                    icon: "error",
                    confirmButtonText: "OK",
                  });
            } else {
                setJwtToken(data.access_token)
                toggleRefresh(true)
                navigate("/admin/landingpage")
            }
        }).catch(error => {
            Swal.fire({
                title: "Fehler",
                text: error.message,
                icon: "error",
                confirmButtonText: "OK",
              });
        })
    }

    useEffect(()=>{
      if(jwtToken !== ""){
        navigate("/admin/landingpage")
      }
  }, [jwtToken, navigate])

    return(
        <div className="col-md-6 offset-md-3">
            <h2>Login</h2>
            <hr />

            <form onSubmit={handleSubmit}>
                <Input
                    title="Email Address"
                    type="email"
                    className="form-control"
                    name="email"
                    autoComplete="email-new"
                    onChange={(event) => setEmail(event.target.value)}
                />

                <Input
                    title="Password"
                    type="password"
                    className="form-control"
                    name="password"
                    autoComplete="password-new"
                    onChange={(event) => setPassword(event.target.value)}
                />
                <hr />
                <input 
                    type="submit"
                    className="btn btn-primary"
                    value="Login"
                />
            </form>
        </div>
    )
}

export default Login;