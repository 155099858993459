import React from "react";
import ImpressumContent from "../components/ImpressumContent"
import { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar"

export default function Impressum(){
  const [data, setData] = useState([])
  const navbarRef = useRef(null)
  useEffect(() => {
    var navbar = navbarRef.current;
    if (window.scrollY > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
    const headers = new Headers()
    headers.append("Content-Type", "application/json")

    const requestOptions = {
        method: "GET",
        headers: headers,
    }

    fetch(`${process.env.REACT_APP_BACKEND}/impressum`, requestOptions)
        .then((response) => response.json())
        .then((data)=>{
            setData(data[0])
        })
        .catch((error)=>{
            console.log(error)
        }
      )  
  }, [navbarRef]);

  return(<>
    <Navbar id={navbarRef} assets="./assets"></Navbar>
    <ImpressumContent data={data}></ImpressumContent>
    <Footer showButton = {false}></Footer>
  </>
  )
}